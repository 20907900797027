const bannerBaseUrl = process.env.S3_BASE_URL + "" + process.env.BANNERS_PATH;
const iconBaseUrl = process.env.S3_BASE_URL + "" + process.env.ICONS_PATH;
const gifBaseUrl = process.env.S3_BASE_URL + "" + process.env.GIFS_PATH;

export default {
  BBLogoSmall: bannerBaseUrl + "BBLogoSmall.png",
  BBLogoHorizontal: bannerBaseUrl + "BBPS_horizontal_logo.png",
  BBLogoCircular: bannerBaseUrl + "BBLogoCircular.png",
  BConnectLogoHorizontal: bannerBaseUrl + "BConnectHorizontal4x.png",
  BConnectLogoCircular: bannerBaseUrl + "BconnectCircular3x.png",
  AvatarHappy: bannerBaseUrl + "AvatarHappy.png",
  AxisWhiteLogo: bannerBaseUrl + "AxisWhiteLogo.png",
  AvatarFeather: bannerBaseUrl + "AvatarFeather.png",
  TechnicalErrorAvatar: bannerBaseUrl + "technical_error_avatar.jpg",
  SomethingWentWrong: bannerBaseUrl + "something_went_wrong.jpg",
  keemat_billpay: bannerBaseUrl + "keemat_billpay_error.png",
  maintenance: bannerBaseUrl + "maintenance.png",
  IconFour: iconBaseUrl + "numberFourIcon.svg",
  IconThree: iconBaseUrl + "numberThreeIcon.svg",
  CashbackWelcome1: bannerBaseUrl + "cashback_info1.png",
  CashbackWelcome2: bannerBaseUrl + "cashback_info2.png",
  CashbackWelcome3: bannerBaseUrl + "cbautopayconstruct2.0/story.png",
  OfferBanner1: bannerBaseUrl + "offerdashboardbanner.png",
  OfferBanner2: bannerBaseUrl + "cashback/offerbanner2.png",
  OfferMainBanner: bannerBaseUrl + "cbautopayconstruct2.0/dashboard.png",
  CbZeroBanner: bannerBaseUrl + "cbautopayconstruct2.0/offers.png",
  CashbackGif: gifBaseUrl + "Billpay-cashback_check_status.gif",
  CashbackPaymentMilestone: bannerBaseUrl + "cashbackmilestone.png",
  PaymentNudge: bannerBaseUrl + "banner@3x.png",
  BillerErrorSBZ: bannerBaseUrl + "Biller%20Errors.png",
  TechnicalErrorSBZ: bannerBaseUrl + "Technical%20Error.png",
  ThankYouSBZ: bannerBaseUrl + "Thank%20you",
  NoBillDueSBZ: bannerBaseUrl + "Success%20(No%20Bills%20Due).png",
  PaymentModeUnavailableSBZ: bannerBaseUrl + "Payment%20mode%20Unavailable.png",
  NoCashbackHistorySBZ: bannerBaseUrl + "No%20Cashback%20History.png",
  EnableAutopaySBZ: bannerBaseUrl + "Enable%20Autopay.png",
  DisableAutopaySBZ: bannerBaseUrl + "Disable%20Autopay.png",
  DeleteBillerSBZ: bannerBaseUrl + "Delete%20Biller.png",
  DeleteSchedulePaymentSBZ: bannerBaseUrl + "Delete%20Schedule%20Biller.png",
  PaymentSuccessGraphicSBZ: bannerBaseUrl + "confirm-graphic.png",
  CreditCardRegLimitSBZ: bannerBaseUrl + "Creditcardregistrationlimit.png",
  ConfirmTickMark: bannerBaseUrl + "confirm-graphic.png",
  UpdateInformation: bannerBaseUrl + "difficultiesinfindingyourdetails.png",
  FilterZeroImage: bannerBaseUrl + "nobillersfoundwithcurrentfilters.png",
  NcmcWalletLimitExceed: bannerBaseUrl + "ncmc%20illustration%201x.png",
  SplashScreen: bannerBaseUrl + "splash%20screen-%20sprint%2064.png", // Splashscreen1.png
};
